import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgAboutCover from "../../assets/images/mission/about-emm/about-emm-cover.png"
import imgAboutSectionOne from "../../assets/images/home/home-section-1.png"
import imgPeopleOne from "../../assets/images/mission/about-emm/people-img-1.png"
import imgPeopleTwo from "../../assets/images/mission/about-emm/people-img-2.png"
import imgPeopleThree from "../../assets/images/mission/about-emm/people-img-3.png"

const AboutEmmPage = () => (
  <Layout lang="ar">
    <SEO title="About EMM" lang="ar" />
    <main>
      <div className="uk-cover-container" data-uk-height-viewport="offset-top: true; offset-bottom: 40">
        {/* <canvas width="100%" height="100%"></canvas> */}
        <img src={imgAboutCover} style={{transform: "translate(50%,-50%)"}} alt="" data-uk-cover />
        <div className="uk-overlay uk-position-cover header-overlay"></div>
      </div>
      <section id="about-emm" style={{marginTop: "-100px", position: "relative"}}>
          <div className="large-container pb6">
            <div className="standard-container standard-bg">
              <div className="flex flex-column flex-row-ns">
                <div className="w-100 w-60-ns pl5-ns pb5-ns z-index-10">
                <div>
                  <h2 className="relative">
                      نبذة عن مشروع الإمارات 
                      <br/>
                      لاستكشاف المريخ
                    <div className="text-underline-heavy"></div>
                  </h2>
                  <p className="mt5">
                  مشروع الإمارات لاستكشاف المريخ هو أول مهمة تقوم بها دولة الإمارات العربية المتحدة لاستكشاف كوكب المريخ. تم تصميم مسبار الأمل للدوران حول كوكب المريخ ودراسة ديناميكية عمل الغلاف الجوي للكوكب الأحمر على نطاق عالمي، وخلال فترات اليوم المختلفة وعلى مدار المواسم المتعاقبة. 
                  </p>  
                  <p>
                  باستخدام ثلاثة أجهزة على متن المسبار سيجمع مشروع الإمارات لاستكشاف المريخ مجموعة من القياسات الأساسية التي تساعدنا على تكوين فهم أعمق لكيفية دوران الغلاف الجوي وطبيعة الطقس في طبقتيه السفلى والوسطى. ثم يقوم الفريق بدمج هذه القياسات مع نتائج رصد الطبقة العليا من الغلاف الجوي، لتكشف هذه القياسات عن الأسباب الكامنة وراء فقدان الطاقة وهروب جسيمات الغلاف الجوي من جاذبية المريخ.  
                  </p>          
                </div>
              </div>
                <div className="w-100 w-40-ns">
                <div>
                  <div className="animated-image-container">
                      <img src={imgAboutSectionOne} alt=""/>
                  </div>
                </div>
              </div>  
                </div>
                <div className="mv2">
                  <h3>
                  مهمة مسبار الأمل
                  </h3>
                  <p className="mt4">
                  سيقوم مشروع الإمارات لاستكشاف المريخ “مسبار الأمل” باستكشاف أعمق التغيرات المناخية في الغلاف الجوي للمريخ من خلال جمع بيانات على مدار اليوم وباختلاف المواسم ومقارنتها ببعضها. كما سيجري المسبار بعض القياسات الأساسية التي تساعدنا على فهم كيفية دوران الغلاف الجوي للمريخ وطبيعة الطقس في كل من طبقتيه السفلى والوسطى، وسيستخدم المسبار في مهمته ثلاثة أجهزة علمية صممت خصيصاً لتُساعده في تحقيق أهداف مهمته. ومن المستهدف أن تساعد هذه القياسات والبيانات بالإضافة إلى مراقبة الطبقات العليا من الغلاف الجوي في فهم أسباب صعود الطاقة وجزئيات الأكسجين والهيدروجين إلى طبقات الغلاف الجوي ومن ثم فهم كيفية هروبها من جاذبية المريخ. ولعل ما يميز “مسبار الأمل” هو المزيج الفريد من الأجهزة العلمية المتطورة التي صممت خصيصاً لهذه المهمة والقدرة على التنقل بين طبقات الغلاف الجوي للمريخ وتغطيته على مدار اليوم، وباختلاف المكان وتغيرالمواسم، وهو ما سيتيح لنا إلقاء نظرة لطالما كنا في أمّس الحاجة إليها على أجواء الكوكب المجاور لنا.
                  </p>
                </div>
              <div className="custom-slider mv5" data-uk-slider>

                  <div className="uk-position-relative">

                      <div className="uk-slider-container uk-light">
                          <ul className="uk-slider-items">
                              <li>
                                  <img src={imgPeopleOne} alt=""/>
                              </li>
                              <li>
                                  <img src={imgPeopleTwo} alt=""/>
                              </li>
                              <li>
                                  <img src={imgPeopleThree} alt=""/>
                              </li>
                          </ul>
                      </div>

                      <div className="uk-hidden@s uk-light uk-text-center">
                      <a className="uk-position-small uk-icon uk-slidenav-previous uk-slidenav custom-slide-nav" href="#" data-uk-slidenav-previous data-uk-slider-item="previous">السابق</a>
                          <a className="uk-position-small uk-icon uk-slidenav-next uk-slidenav custom-slide-nav" href="#" data-uk-slidenav-next data-uk-slider-item="next">التالي</a>
                      </div>

                      <div className="uk-visible@s">
                          <a className="uk-position-center-left-out uk-position-small custom-slide-nav" href="#" data-uk-slidenav-previous data-uk-slider-item="previous">السابق</a>
                          <a className="uk-position-center-right-out uk-position-small custom-slide-nav" href="#" data-uk-slidenav-next data-uk-slider-item="next">التالي</a>
                      </div>

                  </div>

                  <ul className="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
                </div>

                <div className="mv2">
                  <h3>
                  الانضمام إلى الجهود العالمية
                  </h3>
                  <p className="mt4">
                  تلعب المجموعة الاستشارية لبرنامج استكشاف المريخ، دوراً كبيراً في تحديد الأهداف العلمية والأبحاث التي يجب أن يتم دراستها من أجل تعزيز معرفة البشر بالكوكب الأحمر، وتُعد المجموعة منتدى مركزياً يتم من خلاله التوصل إلى توافق في الآراء بين مجتمع علوم المريخ حول العالم. وتشمل أهداف المجموعة لعام 2020 أربعة أهداف هي: 1) تحديد ما إذا كان المريخ قد شهد حياة من قبل، أو ما يزال بإمكانه ذلك. 2) فهم طبيعة الطقس ومعرفة تاريخ المناخ على كوكب المريخ. 3) فهم نشأة وتطور المريخ كنظام جيولوجي. 4) الاستعداد من أجل استكشافه برحلات مأهولة. وينقسم كل هدف من هذه الأهداف الرئيسية إلى مجموعة من الأهداف الفرعية التي تتفرع بدورها إلى هدف فرعي أو أكثر بما يتناسب مع الأبحاث الموصى بها.
                  </p>
                  <p>
                  وترتبط الأهداف العلمية لمشروع الإمارات لاستكشاف المريخ في المقام الأول بالهدف الثاني من أهداف المجموعة الاستشارية لبرنامج استكشاف المريخ، الذي يتمثل في فهم طبيعة الطقس ومعرفة تاريخ المناخ على كوكب المريخ. وعلى وجه التحديد، فإن أهداف مشروع الإمارات لاستكشاف المريخ تندرج تحت الهدف الفرعي الذي يهدف إلى "وصف حالة المناخ على كوكب المريخ والعمليات التي تتحكم فيه في ظل التكوين المداري الحالي للكوكب" وذلك من خلال دراسة الطبقة العليا والسفلى من الغلاف الجوي للمريخ بشكل متزامن.
                  </p>
                </div>
            </div>
            <div className="standard-container standard-bg flex flex-column flex-row-ns">
                  <div className="w-100 w-40-ns">
                      <div className="animated-image-container">
                          <img src={imgAboutSectionOne} alt=""/>
                      </div>
                  </div>
                  <div className="w-100 w-60-ns pr5-ns pb5-ns z-index-10">
                    <div className="negative-margin-left-150 pl5-ns pt5-ns">
                      <h3>
                      الهدف
                      </h3>
                      <p className="uk-text-bold text-light">
                      تقديم
                        <span className="text-highlight-red"> أول صورة متكاملة </span>
                        للغلاف الجوي للمريخ
                      </p>
                      <div className="flex flex-column mv4">
                        <div className="stacked-card flex justify-between items-center">
                          <div className="w-20 flex justify-center items-center">
                            <span className="uk-text-lead text-highlight-brown stacked-card-count">
                              01
                            </span>
                          </div>
                          <div className="w-80">
                            <p>
                            تكوين فهم أعمق حول التغيرات المناخية على سطح كوكب المريخ، ورسم خارطة توضح طبيعة طقسه الحالي عبر دراسة الطبقة السفلى من غلافه الجوي.
                            </p>
                          </div>
                        </div>
                        <div className="stacked-card flex justify-between items-center">
                          <div className="w-20 flex justify-center items-center">
                            <span className="uk-text-lead text-highlight-brown stacked-card-count">
                              02
                            </span>
                          </div>
                          <div className="w-80">
                            <p>
                            دراسة تأثير التغيرات المناخية على المريخ في تشكيل ظاهرة هروب غازي الأكسجين والهيدروجين من غلافه الجوي عبر دراسة العلاقة بين طبقات الغلاف الجوي السفلية والعلوية.
                            </p>
                          </div>
                        </div>
                        <div className="stacked-card flex justify-between items-center">
                          <div className="w-20 flex justify-center items-center">
                            <span className="uk-text-lead text-highlight-brown stacked-card-count">
                              03
                            </span>
                          </div>
                          <div className="w-80">
                            <p>
                            إجراء دراسات معمقة حول ظاهرة هروب غازي الأكسجين والهيدروجين من الغلاف الجوي لكوكب المريخ، ومعرفة أسباب حدوثها.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
          
          </div>  
      </section>
    </main>
  </Layout>
)

export default AboutEmmPage
